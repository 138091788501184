import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('Settings', [
      'permissions'
    ]),
    hasTabPermission() {
      const routePermissionKey = this.$route.matched[0].meta.tabPermissionKey
      // Compression permission key which in page with control if has permission which user for it
      return this.permissions.some(item => item.permissionKey === routePermissionKey)
    }
  }
}
