import store from "@/store";
import HTTP from "@/utils/http";
import router from "@/router";
import AuthorizationContext from "./keycloak-instance";
import requestHandler from "@/utils/requestHandler";

export default function (initApp) {
  const isSupportUser = location.pathname.includes("supportuser");

  if (isSupportUser) {
    handleSupportUser(initApp);
  } else {
    initAuthorizationContext(initApp);
  }
}

function handleSupportUser(initApp) {
  store.commit("Global/SET_SUPPORT_USER_STATUS", true);
  initApp();
}

async function initAuthorizationContext(initApp) {
  try {
    const response = await AuthorizationContext.init();
    if (response) {
      setSSOInfo();

      const user = AuthorizationContext.user
      const isInventoryUser = user.memberOf.includes("/INVENTORY");
      if (isInventoryUser) {
        const iysPermissions = Object.keys(user.profile.attributes).filter(attr => {
          return attr.startsWith('iys')
        })
        const productsUrl = window.VUE_APP_SIMPRA_PRODUCTS_URL || process.env.VUE_APP_SIMPRA_PRODUCTS_URL

        if (iysPermissions.length === 0) {
          window.open(productsUrl, '_blank')
        }
        await handleInventoryUser(initApp);
      } else {
        handleNonInventoryUser(initApp);
      }
    }
  } catch (error) {
    AuthorizationContext.logout()
    store.dispatch("Auth/Logout")
    console.log("SSO ERROR -> ", error);
  }
}

function setSSOInfo() {
  const { sub, sid } = AuthorizationContext.keycloakInstance.tokenParsed;
  store.commit("Auth/SET_SSO_INFO", {
    session_id: sid,
    device_id: sub,
  });

  HTTP.defaults.headers.common["Authorization"] =
    "Bearer " + AuthorizationContext.keycloakInstance.token;
}

async function handleInventoryUser(initApp) {
  await requestHandler({
    title: "SSO Login",
    methodName: "SSO/Login",
    method() {
      return HTTP.post("User/sso/login");
    },
    async success() {
      await requestHandler({
        title: "User SSO",
        methodName: "User/SSO",
        method() {
          return HTTP.get("User/sso");
        },
        async success(result) {
          const token = JSON.parse(localStorage.getItem('simpraDEV')).Auth?.Token?.value
          await setUserInfo(result)
          if(token) {
            initApp()
            if (/\/(?:login|register)/.test(location.pathname)) {
              router.push({ name: "Dashboard" });
            }
          } else {
            window.location.href = window.VUE_APP_SIMPRA_PRODUCTS_URL || process.env.VUE_APP_SIMPRA_PRODUCTS_URL
          } 
        },
      });
    },
  });
}

function handleNonInventoryUser(initApp) {
  initApp();
  router.push({ name: "Register" });
}

async function setUserInfo(result) {
  store.commit("Auth/USER_CREATE", result.data.user);
  store.commit("Auth/TENANT_CREATE", result.data.tenant);
  store.commit(
    "Auth/ControlAgreement",
    result.data.needsNewAgreementConfirmation
  );
  store.commit("Auth/CHANGE_TOKEN", {
    value: AuthorizationContext.keycloakInstance.token,
    type: result.data.tenant ? "tenant" : "user",
    refreshToken: AuthorizationContext.keycloakInstance.refreshToken,
  });

  await store.dispatch("Settings/fetchUserForPermission", null, {
    root: true,
  });
}
