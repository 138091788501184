export default [
  {
    value: 1,
    name: 'tax'
  },
  {
    value: 2,
    name: 'group'
  },
  {
    value: 3,
    name: 'supplier'
  },
  {
    value: 4,
    name: 'units'
  },
  {
    value: 5,
    name: 'stock'
  },
  {
    value: 6,
    name: 'stores'
  },
  {
    value: 7,
    name: 'lists'
  },
  {
    value: 8,
    name: 'priceQuote'
  },
  {
    value: 9,
    name: 'despatch'
  },
  {
    value: 10,
    name: 'payments'
  },
  {
    value: 11,
    name: 'requests'
  },
  {
    value: 12,
    name: 'approveOperations'
  },
  {
    value: 13,
    name: 'recipe'
  },
  {
    value: 14,
    name: 'menu-items'
  },
  {
    value: 15,
    name: 'production'
  },
  {
    value: 16,
    name: 'yield'
  },
  {
    value: 17,
    name: 'stockonhand'
  },
  {
    value: 18,
    name: 'transfer'
  },
  {
    value: 19,
    name: 'waste'
  },
  {
    value: 20,
    name: 'inventory'
  },
  {
    value: 21,
    name: 'scheduledjob'
  },
  {
    value: 22,
    name: 'optionalFields'
  },
  {
    value: 23,
    name: 'orderIntegrationStatus'
  },
  {
    value: 24,
    name: 'integration'
  },
  {
    value: 25,
    name: 'menuitemsconfig'
  },
  {
    value: 26,
    name: 'authorization'
  }
]
