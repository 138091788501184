const Page = {
  number: 1,
  size: 20,
  total: 0
}

const PageGetters = ({ state, stateName }) => ({
  ...state[stateName],
  last: Math.ceil(state[stateName].total / state[stateName].size)
})

const getPaginationNumber = ({ selectedPage, currentPage }) => {
  let PAGE_NUMBER

  switch (selectedPage) {
    case 'prev':
      PAGE_NUMBER = currentPage - 1
      break
    case 'next':
      PAGE_NUMBER = currentPage + 1
      break
    default:
      PAGE_NUMBER = selectedPage
      break
  }

  return Number(PAGE_NUMBER)
}
export {
  Page,
  PageGetters,
  getPaginationNumber
}
