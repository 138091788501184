export default [
  {
    code: 'CELERY',
    icon: 'icon-allergen-celery'
  },
  {
    code: 'CEREALS_CONTAINING_GLUTEN',
    icon: 'icon-allergen-gluten'
  },
  {
    code: 'CRUSTACEANS',
    icon: 'icon-allergen-crustaceans'
  },
  {
    code: 'EGGS',
    icon: 'icon-allergen-egg'
  },
  {
    code: 'FISH',
    icon: 'icon-allergen-fish'
  },
  {
    code: 'LUPIN',
    icon: 'icon-allergen-lupin'
  },
  {
    code: 'MILK',
    icon: 'icon-allergen-milk'
  },
  {
    code: 'MOLLUSCS',
    icon: 'icon-allergen-mollusks'
  },
  {
    code: 'MUSTARD',
    icon: 'icon-allergen-mustard'
  },
  {
    code: 'NUTS',
    icon: 'icon-allergen-nuts'
  },
  {
    code: 'PEANUTS',
    icon: 'icon-allergen-peanut'
  },
  {
    code: 'SESAME_SEEDS',
    icon: 'icon-allergen-sesame'
  },
  {
    code: 'SOYA',
    icon: 'icon-allergen-soy'
  },
  {
    code: 'SULPHUR_DIOXIDE',
    icon: 'icon-allergen-sulfurdioxide'
  },
  {
    code: 'CUSTOM',
    icon: 'simpra-logo'
  }
]
