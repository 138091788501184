import { mapActions } from 'vuex'
import { Validator } from 'vee-validate'

export default {
  data() {
    return {
      inventoryValidation: false
    }
  },
  created() {
    Validator.extend('verify_inventory', {
            // Custom validation message
      getMessage: () => this.$t('Inventory.closedInventoryError'),
            // Custom validation rule
      validate: value => new Promise(resolve => {
        let params = this.checkInventoryParams
        let control = Object.keys(params).filter(item => params[item] === null || params[item] === '-1')
        if (typeof params.storeId === 'object') {
          let firstParams = {
            ...params,
            storeId: params.storeId[0]
          }
          let secondParams = {
            ...params,
            storeId: params.storeId[1]
          }

          if (params.storeId[0] !== '-1' && params.storeId[1] !== '-1' && control.length === 0) {
            this.checkInventoryAll(firstParams)
                        .then(res => {
                          let hasFirstInventory = res.data.hasInventory
                          if (!hasFirstInventory) {
                            this.checkInventoryAll(secondParams)
                                    .then(res => {
                                      let hasSecondInventory = res.data.hasInventory
                                      this.inventoryValidation = hasFirstInventory || hasSecondInventory
                                      resolve({
                                        valid: !this.inventoryValidation
                                      })
                                    })
                          } else {
                            this.inventoryValidation = hasFirstInventory
                            resolve({
                              valid: !hasFirstInventory
                            })
                          }
                        })
          }
        } else {
          if (control.length === 0) {
            this.checkInventoryAll(params)
              .then(res => {
                let hasInventory = res.data.hasInventory
                this.inventoryValidation = hasInventory
                resolve({
                  valid: !hasInventory
                })
              })
          } else {
            resolve({
              valid: true
            })
          }
        }
      })
    })
  },
  methods: {
    ...mapActions('Inventories', [
      'checkInventoryAll'
    ])
  }
}
