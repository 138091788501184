export default[
  {
  long: "Afghanistan",
  code: "+93",
  short: "AF"
  },
  {
  long: "Aland Islands",
  code: "+358",
  short: "AX"
  },
  {
  long: "Albania",
  code: "+355",
  short: "AL"
  },
  {
  long: "Algeria",
  code: "+213",
  short: "DZ"
  },
  {
  long: "AmericanSamoa",
  code: "+1684",
  short: "AS"
  },
  {
  long: "Andorra",
  code: "+376",
  short: "AD"
  },
  {
  long: "Angola",
  code: "+244",
  short: "AO"
  },
  {
  long: "Anguilla",
  code: "+1264",
  short: "AI"
  },
  /* {
  long: "Antarctica",
  code: "+672",
  short: "AQ"
  }, */
  {
  long: "Antigua and Barbuda",
  code: "+1268",
  short: "AG"
  },
  {
  long: "Argentina",
  code: "+54",
  short: "AR"
  },
  {
  long: "Armenia",
  code: "+374",
  short: "AM"
  },
  {
  long: "Aruba",
  code: "+297",
  short: "AW"
  },
 /*  {
    long: "Ascension",
    code: '+247',
    short: "AC",
  }, */
  {
  long: "Australia",
  code: "+61",
  short: "AU"
  },
  {
  long: "Austria",
  code: "+43",
  short: "AT"
  },
  {
  long: "Azerbaijan",
  code: "+994",
  short: "AZ"
  },
  {
  long: "Bahamas",
  code: "+1242",
  short: "BS"
  },
  {
  long: "Bahrain",
  code: "+973",
  short: "BH"
  },
  {
  long: "Bangladesh",
  code: "+880",
  short: "BD"
  },
  {
  long: "Barbados",
  code: "+1246",
  short: "BB"
  },
  {
  long: "Belarus",
  code: "+375",
  short: "BY"
  },
  {
  long: "Belgium",
  code: "+32",
  short: "BE"
  },
  {
  long: "Belize",
  code: "+501",
  short: "BZ"
  },
  {
  long: "Benin",
  code: "+229",
  short: "BJ"
  },
  {
  long: "Bermuda",
  code: "+1441",
  short: "BM"
  },
  {
  long: "Bhutan",
  code: "+975",
  short: "BT"
  },
  {
  long: "Bolivia",
  code: "+591",
  short: "BO"
  },
  {
  long: "Bosnia and Herzegovina",
  code: "+387",
  short: "BA"
  },
  {
  long: "Botswana",
  code: "+267",
  short: "BW"
  },
  {
  short: "BV",
  long: "Bouvet Island",
  code: '+47',
  },
  {
  long: "Brazil",
  code: "+55",
  short: "BR"
  },
  {
  long: "British Indian Ocean Territory",
  code: "+246",
  short: "IO"
  },
  {
  long: "Brunei Darussalam",
  code: "+673",
  short: "BN"
  },
  {
  long: "Bulgaria",
  code: "+359",
  short: "BG"
  },
  {
  long: "Burkina Faso",
  code: "+226",
  short: "BF"
  },
  {
  long: "Burundi",
  code: "+257",
  short: "BI"
  },
  {
  long: "Cambodia",
  code: "+855",
  short: "KH"
  },
  {
  long: "Cameroon",
  code: "+237",
  short: "CM"
  },
  {
  long: "Canada",
  code: "+1",
  short: "CA"
  },
  {
  long: "Canary Islands",
  code: "+0034",
  short: "IC"
  },
  {
  long: "Cape Verde",
  code: "+238",
  short: "CV"
  },
  {
  long: "Caribbean Netherlands",
  code: "+599",
  short: "BQ"
  },
  {
  long: "Cayman Islands",
  code: "+ 345",
  short: "KY"
  },
  {
  long: "Central African Republic",
  code: "+236",
  short: "CF"
  },
  {
  long: "Chad",
  code: "+235",
  short: "TD"
  },
  {
  long: "Chile",
  code: "+56",
  short: "CL"
  },
  {
  long: "China",
  code: "+86",
  short: "CN"
  },
  {
  long: "Christmas Island",
  code: "+61",
  short: "CX"
  },
  {
  long: "Cocos Islands",
  code: "+61",
  short: "CC"
  },
  {
  long: "Colombia",
  code: "+57",
  short: "CO"
  },
  {
  long: "Comoros",
  code: "+269",
  short: "KM"
  },
  {
  long: "Congo",
  code: "+242",
  short: "CG"
  },
  {
  long: "Congo",
  code: "+243",
  short: "CD"
  },
  {
  long: "Cook Islands",
  code: "+682",
  short: "CK"
  },
  {
  long: "Costa Rica",
  code: "+506",
  short: "CR"
  },
/*   {
  long: "Cote d'Ivoire",
  code: "+225",
  short: "CI"
  }, */
  {
  long: "Croatia",
  code: "+385",
  short: "HR"
  },
  {
  long: "Cuba",
  code: "+53",
  short: "CU"
  },
  {
    long: "Curaçao",
    short: "CW",
    code: '+599'
  },
  {
  long: "Cyprus",
  code: "+357",
  short: "CY"
  },
  {
  long: "Czech Republic",
  code: "+420",
  short: "CZ"
  },
  {
  long: "Denmark",
  code: "+45",
  short: "DK"
  },
  {
  long: "Djibouti",
  code: "+253",
  short: "DJ"
  },
  {
  long: "Dominica",
  code: "+1767",
  short: "DM"
  },
  {
  long: "Dominican Republic",
  code: "+1849",
  short: "DO"
  },
  {
  long: "Ecuador",
  code: "+593",
  short: "EC"
  },
  {
  long: "Egypt",
  code: "+20",
  short: "EG"
  },
  {
  long: "El Salvador",
  code: "+503",
  short: "SV"
  },
  {
  long: "Equatorial Guinea",
  code: "+240",
  short: "GQ"
  },
  {
  long: "Eritrea",
  code: "+291",
  short: "ER"
  },
  {
  long: "Estonia",
  code: "+372",
  short: "EE"
  },
  {
  long: "Ethiopia",
  code: "+251",
  short: "ET"
  },
  {
  long: "Falkland Islands (Malvinas)",
  code: "+500",
  short: "FK"
  },
  {
  long: "Faroe Islands",
  code: "+298",
  short: "FO"
  },
  {
  long: "Fiji",
  code: "+679",
  short: "FJ"
  },
  {
  long: "Finland",
  code: "+358",
  short: "FI"
  },
  {
  long: "France",
  code: "+33",
  short: "FR"
  },
  /* {
  long: "French Guiana",
  code: "+594",
  short: "GF"
  }, */
  {
  long: "French Polynesia",
  code: "+689",
  short: "PF"
  },
 /*  {
    long: "French Southern and Antarctic Lands",
    code: "+262",
    short: "TF"
  }, */
  {
  long: "Gabon",
  code: "+241",
  short: "GA"
  },
  {
  long: "Gambia",
  code: "+220",
  short: "GM"
  },
  {
  long: "Georgia",
  code: "+995",
  short: "GE"
  },
  {
  long: "Germany",
  code: "+49",
  short: "DE"
  },
  {
  long: "Ghana",
  code: "+233",
  short: "GH"
  },
  {
  long: "Gibraltar",
  code: "+350",
  short: "GI"
  },
  {
  long: "Greece",
  code: "+30",
  short: "GR"
  },
  {
  long: "Greenland",
  code: "+299",
  short: "GL"
  },
  {
  long: "Grenada",
  code: "+1473",
  short: "GD"
  },
 /*  {
  long: "Guadeloupe",
  code: "+590",
  short: "GP"
  }, */
  {
  long: "Guam",
  code: "+1671",
  short: "GU"
  },
  {
  long: "Guatemala",
  code: "+502",
  short: "GT"
  },
  {
  long: "Guernsey",
  code: "+44",
  short: "GG"
  },
  {
  long: "Guinea",
  code: "+224",
  short: "GN"
  },
  {
  long: "Guinea-Bissau",
  code: "+245",
  short: "GW"
  },
  {
  long: "Guyana",
  code: "+595",
  short: "GY"
  },
  {
  long: "Haiti",
  code: "+509",
  short: "HT"
  },
  /* {
    long: "H. Island & M. Islands",
    code: "+672",
    short: "HM"
    }, */
  {
  long: "Holy See",
  code: "+379",
  short: "VA"
  },
  {
  long: "Honduras",
  code: "+504",
  short: "HN"
  },
  {
  long: "Hong Kong",
  code: "+852",
  short: "HK"
  },
  {
  long: "Hungary",
  code: "+36",
  short: "HU"
  },
  {
  long: "Iceland",
  code: "+354",
  short: "IS"
  },
  {
  long: "India",
  code: "+91",
  short: "IN"
  },
  {
  long: "Indonesia",
  code: "+62",
  short: "ID"
  },
  {
  long: "Iran",
  code: "+98",
  short: "IR"
  },
  {
  long: "Iraq",
  code: "+964",
  short: "IQ"
  },
  {
  long: "Ireland",
  code: "+353",
  short: "IE"
  },
  {
  long: "Isle of Man",
  code: "+44",
  short: "IM"
  },
  {
  long: "Israel",
  code: "+972",
  short: "IL"
  },
  {
  long: "Italy",
  code: "+39",
  short: "IT"
  },
  {
  long: "Jamaica",
  code: "+1876",
  short: "JM"
  },
  {
  long: "Japan",
  code: "+81",
  short: "JP"
  },
  {
  long: "Jersey",
  code: "+44",
  short: "JE"
  },
  {
  long: "Jordan",
  code: "+962",
  short: "JO"
  },
  {
  long: "Kazakhstan",
  code: "+77",
  short: "KZ"
  },
  {
  long: "Kenya",
  code: "+254",
  short: "KE"
  },
  {
  long: "Kiribati",
  code: "+686",
  short: "KI"
  },
  {
  long: "Korea",
  code: "+850",
  short: "KP"
  },
  {
  long: "Korea",
  code: "+82",
  short: "KR"
  },
  {
    long: "Kosovo",
    code: "+383",
    short: "XK"
  },
  
  {
  long: "Kuwait",
  code: "+965",
  short: "KW"
  },
  {
  long: "Kyrgyzstan",
  code: "+996",
  short: "KG"
  },
  {
  long: "Laos",
  code: "+856",
  short: "LA"
  },
  {
  long: "Latvia",
  code: "+371",
  short: "LV"
  },
  {
  long: "Lebanon",
  code: "+961",
  short: "LB"
  },
  {
  long: "Lesotho",
  code: "+266",
  short: "LS"
  },
  {
  long: "Liberia",
  code: "+231",
  short: "LR"
  },
  {
  long: "Libyan Arab Jamahiriya",
  code: "+218",
  short: "LY"
  },
  {
  long: "Liechtenstein",
  code: "+423",
  short: "LI"
  },
  {
  long: "Lithuania",
  code: "+370",
  short: "LT"
  },
  {
  long: "Luxembourg",
  code: "+352",
  short: "LU"
  },
  {
  long: "Macao",
  code: "+853",
  short: "MO"
  },
  {
  long: "Macedonia",
  code: "+389",
  short: "MK"
  },
  {
  long: "Madagascar",
  code: "+261",
  short: "MG"
  },
  {
  long: "Malawi",
  code: "+265",
  short: "MW"
  },
  {
  long: "Malaysia",
  code: "+60",
  short: "MY"
  },
  {
  long: "Maldives",
  code: "+960",
  short: "MV"
  },
  {
  long: "Mali",
  code: "+223",
  short: "ML"
  },
  {
  long: "Malta",
  code: "+356",
  short: "MT"
  },
  {
  long: "Marshall Islands",
  code: "+692",
  short: "MH"
  },
  {
  long: "Martinique",
  code: "+596",
  short: "MQ"
  },
  {
  long: "Mauritania",
  code: "+222",
  short: "MR"
  },
  {
  long: "Mauritius",
  code: "+230",
  short: "MU"
  },
 /*  {
  long: "Mayotte",
  code: "+262",
  short: "YT"
  }, */
  {
  long: "Mexico",
  code: "+52",
  short: "MX"
  },
  {
  long: "Micronesia",
  code: "+691",
  short: "FM"
  },
  {
  long: "Moldova",
  code: "+373",
  short: "MD"
  },
  {
  long: "Monaco",
  code: "+377",
  short: "MC"
  },
  {
  long: "Mongolia",
  code: "+976",
  short: "MN"
  },
  {
  long: "Montenegro",
  code: "+382",
  short: "ME"
  },
  {
  long: "Montserrat",
  code: "+1664",
  short: "MS"
  },
  {
  long: "Morocco",
  code: "+212",
  short: "MA"
  },
  {
  long: "Mozambique",
  code: "+258",
  short: "MZ"
  },
  {
  long: "Myanmar",
  code: "+95",
  short: "MM"
  },
  {
  long: "Namibia",
  code: "+264",
  short: "NA"
  },
  {
  long: "Nauru",
  code: "+674",
  short: "NR"
  },
  {
  long: "Nepal",
  code: "+977",
  short: "NP"
  },
  {
  long: "Netherlands",
  code: "+31",
  short: "NL"
  },
 /*  {
  long: "Netherlands Antilles",
  code: "+599",
  short: "AN"
  }, */
 /*  {
  long: "New Caledonia",
  code: "+687",
  short: "NC"
  }, */
  {
  long: "New Zealand",
  code: "+64",
  short: "NZ"
  },
  {
  long: "Nicaragua",
  code: "+505",
  short: "NI"
  },
  {
  long: "Niger",
  code: "+227",
  short: "NE"
  },
  {
  long: "Nigeria",
  code: "+234",
  short: "NG"
  },
  {
  long: "Niue",
  code: "+683",
  short: "NU"
  },
  {
  long: "Norfolk Island",
  code: "+672",
  short: "NF"
  },
  {
  long: "Northern Mariana Islands",
  code: "+1670",
  short: "MP"
  },
  {
  long: "Norway",
  code: "+47",
  short: "NO"
  },
  {
  long: "Oman",
  code: "+968",
  short: "OM"
  },
  {
  long: "Pakistan",
  code: "+92",
  short: "PK"
  },
  {
  long: "Palau",
  code: "+680",
  short: "PW"
  },
  {
  long: "Palestinian Territory",
  code: "+970",
  short: "PS"
  },
  {
  long: "Panama",
  code: "+507",
  short: "PA"
  },
  {
  long: "Papua New Guinea",
  code: "+675",
  short: "PG"
  },
  {
  long: "Paraguay",
  code: "+595",
  short: "PY"
  },
  {
  long: "Peru",
  code: "+51",
  short: "PE"
  },
  {
  long: "Philippines",
  code: "+63",
  short: "PH"
  },
  {
  long: "Pitcairn",
  code: "+872",
  short: "PN"
  },
  {
  long: "Poland",
  code: "+48",
  short: "PL"
  },
  {
  long: "Portugal",
  code: "+351",
  short: "PT"
  },
  {
  long: "Puerto Rico",
  code: "+1939",
  short: "PR"
  },
  {
  long: "Qatar",
  code: "+974",
  short: "QA"
  },
  {
  long: "Romania",
  code: "+40",
  short: "RO"
  },
  {
  long: "Russia",
  code: "+7",
  short: "RU"
  },
  {
  long: "Rwanda",
  code: "+250",
  short: "RW"
  },
/*   {
  long: "Reunion",
  code: "+262",
  short: "RE"
  }, */
  {
  long: "Saint Barthelemy",
  code: "+590",
  short: "BL"
  },
/*   {
  long: "Saint Helena",
  code: "+290",
  short: "SH"
  }, */
  {
  long: "Saint Kitts and Nevis",
  code: "+1869",
  short: "KN"
  },
  {
  long: "Saint Lucia",
  code: "+1758",
  short: "LC"
  },
 /*  {
  long: "Saint Martin",
  code: "+590",
  short: "MF"
  }, */
/*   {
  long: "Saint Pierre",
  code: "+508",
  short: "PM"
  }, */
  {
  long: "Saint Vincent",
  code: "+1784",
  short: "VC"
  },
  {
  long: "Samoa",
  code: "+685",
  short: "WS"
  },
  {
  long: "San Marino",
  code: "+378",
  short: "SM"
  },
  {
  long: "Sao Tome and Principe",
  code: "+239",
  short: "ST"
  },
  {
  long: "Saudi Arabia",
  code: "+966",
  short: "SA"
  },
  {
  long: "Senegal",
  code: "+221",
  short: "SN"
  },
  {
  long: "Serbia",
  code: "+381",
  short: "RS"
  },
  {
  long: "Seychelles",
  code: "+248",
  short: "SC"
  },
  {
  long: "Sierra Leone",
  code: "+232",
  short: "SL"
  },
  {
  long: "Singapore",
  code: "+65",
  short: "SG"
  },
  {
    long: "Sint Maarten",
    code: "+721",
    short: "SX"
    },
  
  {
  long: "Slovakia",
  code: "+421",
  short: "SK"
  },
  {
  long: "Slovenia",
  code: "+386",
  short: "SI"
  },
  {
  long: "Solomon Islands",
  code: "+677",
  short: "SB"
  },
  {
  long: "Somalia",
  code: "+252",
  short: "SO"
  },
  {
  long: "South Africa",
  code: "+27",
  short: "ZA"
  },
  {
  long: "South Sudan",
  code: "+211",
  short: "SS"
  },
  /* {
  long: "South Georgia, South Sandwich Islands",
  code: "+500",
  short: "GS"
  }, */
  {
  long: "Spain",
  code: "+34",
  short: "ES"
  },
  {
  long: "Sri Lanka",
  code: "+94",
  short: "LK"
  },
  {
  long: "Sudan",
  code: "+249",
  short: "SD"
  },
  {
  long: "Surilong",
  code: "+597",
  short: "SR"
  },
  {
  long: "Svalbard and Jan Mayen",
  code: "+47",
  short: "SJ"
  },
  {
  long: "Swaziland",
  code: "+268",
  short: "SZ"
  },
  {
  long: "Sweden",
  code: "+46",
  short: "SE"
  },
  {
  long: "Switzerland",
  code: "+41",
  short: "CH"
  },
  {
  long: "Syrian Arab Republic",
  code: "+963",
  short: "SY"
  },
  {
  long: "Taiwan",
  code: "+886",
  short: "TW"
  },
  {
  long: "Tajikistan",
  code: "+992",
  short: "TJ"
  },
  {
  long: "Tanzania",
  code: "+255",
  short: "TZ"
  },
  {
  long: "Thailand",
  code: "+66",
  short: "TH"
  },
  {
  long: "Timor-Leste",
  code: "+670",
  short: "TL"
  },
  {
  long: "Togo",
  code: "+228",
  short: "TG"
  },
  {
  long: "Tokelau",
  code: "+690",
  short: "TK"
  },
  {
  long: "Tonga",
  code: "+676",
  short: "TO"
  },
  {
  long: "Trinidad and Tobago",
  code: "+1868",
  short: "TT"
  },
  {
  long: "Tunisia",
  code: "+216",
  short: "TN"
  },
  {
  long: "Turkey",
  code: "+90",
  short: "TR"
  },
  {
  long: "Turkmenistan",
  code: "+993",
  short: "TM"
  },
  {
  long: "Turks and Caicos Islands",
  code: "+1649",
  short: "TC"
  },
  {
  long: "Tuvalu",
  code: "+688",
  short: "TV"
  },
 /*  {
    long: "Tristan da Cunha",
    code: "+290",
    short: "TA"
    }, */
  {
  long: "Uganda",
  code: "+256",
  short: "UG"
  },
  {
  long: "Ukraine",
  code: "+380",
  short: "UA"
  },
  {
  long: "United Arab Emirates",
  code: "+971",
  short: "AE"
  },
  {
  long: "United Kingdom",
  code: "+44",
  short: "GB"
  },
  {
  long: "United States",
  code: "+1",
  short: "US"
  },
  {
  long: "Uruguay",
  code: "+598",
  short: "UY"
  },
  {
  long: "Uzbekistan",
  code: "+998",
  short: "UZ"
  },
  {
  long: "Vanuatu",
  code: "+678",
  short: "VU"
  },
  {
  long: "Venezuela",
  code: "+58",
  short: "VE"
  },
  {
  long: "Vietnam",
  code: "+84",
  short: "VN"
  },
  {
  long: "Virgin Islands, British",
  code: "+1284",
  short: "VG"
  },
  {
  long: "Virgin Islands, U.S.",
  code: "+1340",
  short: "VI"
  },
/*   {
  long: "Wallis and Futuna",
  code: "+681",
  short: "WF"
  }, */
  {
    long: "Western Sahara",
    code: '+681',
    short: "EH",
  },
  {
  long: "Yemen",
  code: "+967",
  short: "YE"
  },
  {
  long: "Zambia",
  code: "+260",
  short: "ZM"
  },
  {
  long: "Zimbabwe",
  code: "+263",
  short: "ZW"
  }
  ]