import Vue from "vue";
import colors from "@/stylesheet/config/colors.module.scss";
import fontsizes from "@/stylesheet/config/fontsizes.module.scss";

const parsedColors = colors;
const parsedFontSize = fontsizes;

Object.defineProperty(Vue.prototype, "$colors", {
  get() {
    return parsedColors;
  }
});

Object.defineProperty(Vue.prototype, "$fontsizes", {
  get() {
    return JSON.parse(JSON.stringify(parsedFontSize));
  }
});

export default {
  parsedColors,
  parsedFontSize
};
