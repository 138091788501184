import { startLoading, stopLoading } from "@/utils/loading";
import store from "@/store";
import { Print } from "./helper";
import router from "@/router";
import { setTranslations } from "@/i18n";
import AuthorizationContext from "@/sso/keycloak-instance";

let i18n = {};

const requestHandler = async ({
  title,
  methodName,
  success,
  method,
  incorrect,
  failed,
}) => {
  const ignoredMethodsForErrors = [
    "suspendSalesExist",
    "getProtelIntegratorAliasData",
  ];
  const dispatch = store.dispatch;
  const isSupportUser = store.getters["Global/isSupportUser"];

  try {
    if (!navigator.onLine) {
      location.reload(true);
      return false;
    }
    const result = method
      ? await startLoading(dispatch, methodName, method)
      : null;
    if (result.status >= 400) {
      throw result.data;
    }
    let returnValue = result;
    if (typeof success === "function") {
      returnValue = await success.call(this, result);
    }
    Print(title, returnValue);

    return returnValue;
  } catch (error) {
    i18n = await setTranslations();
    stopLoading(dispatch, methodName);
    const isMethodIgnoredForErrors = ignoredMethodsForErrors.some(
      (metName) => metName === methodName
    );

    Print(title, error);
    let errorCode =
      error && error.response && error.response.data.code
        ? error.response.data.code
        : "";
    let errorMessage;
    if (errorCode) {
      errorMessage = i18n.t(`ApiErrorCode.${errorCode}`);
    } else {
      errorMessage = i18n.t("Global.global_hasError");
    }

    if (typeof incorrect === "function") {
      return errorCode;
    }

    if (typeof failed === "function") {
      await failed.call(this, error.response);
    }

    if (
      error.response &&
      error.response.status !== 401 &&
      error.response.status < 500 &&
      !isMethodIgnoredForErrors
    ) {
      dispatch("Notify/notifyErrorShow", { errorMessage }); // alert(message)
    }

    if (
      (!error.response || error.response.status >= 500) &&
      !isMethodIgnoredForErrors
    ) {
      router.push({
        name: "httpErrors",
        params: {
          httpCode: 503,
          oldRouteName: router.app._route.name,
          oldRoutePath: router.app._route.path,
        },
      });
    }

    if (error.response && error.response.status === 401) {
      let refreshToken;
      if (isSupportUser) {
        const result = await dispatch("Auth/RefreshToken");
        refreshToken = result;
      } else {
        const result = await AuthorizationContext.getNewToken();
        refreshToken = result;
        if (result) {
          const oldToken = store.getters["Auth/Token"];
          store.commit("Auth/CHANGE_TOKEN", {
            value: AuthorizationContext.keycloakInstance.token,
            type: oldToken.type,
            refreshToken: AuthorizationContext.keycloakInstance.refreshToken,
          });
        } else {
          AuthorizationContext.logout();
          dispatch("Auth/Logout");
        }
      }
      if (refreshToken) {
        try {
          const resultAgain = await startLoading(dispatch, methodName, method);
          if (resultAgain.status >= 400) {
            throw resultAgain.data;
          }
          let returnValueAgain = resultAgain;
          if (typeof success === "function") {
            returnValueAgain = await success.call(this, resultAgain);
          }
          return returnValueAgain;
        } catch (resultAgainError) {
          stopLoading(dispatch, methodName);
          Print(title, resultAgainError);
          let errorCode = resultAgainError.response.data.code || "";
          let errorMessage;
          if (errorCode) {
            errorMessage = i18n.t(`ApiErrorCode.${errorCode}`);
          } else {
            errorMessage = "";
          }
          if (error.response.status !== 401)
            dispatch("Notify/notifyErrorShow", { errorMessage }); // alert(message)
          return false;
        }
      } else {
        if (isSupportUser) {
          router.push({
            name: "Login",
          });
        } else {
          AuthorizationContext.logout();
        }
        dispatch("Auth/Logout");
      }
    }
    return false;
  }
};

export default requestHandler;
