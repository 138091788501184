class FeatureToggleRouter {
  /* eslint-disable */ 
  static FeatureNotRegisteredError = feature => {
    return new Error(`Feature ${feature} is not registered`)
  }

  setFeatures(config) {
    this.config = config
    return this
  }

  isActive(feature) {
    if (feature in this.config) {
      return this.config[feature]
    }
    throw FeatureToggleRouter.FeatureNotRegisteredError(feature)
  }

  activate(feature) {
    if (feature in this.config) {
      this.config[feature] = true
      return this
    }
    throw FeatureToggleRouter.FeatureNotRegisteredError(feature)
  }

  deactivate(feature) {
    if (feature in this.config) {
      this.config[feature] = false
      return this
    }
    throw FeatureToggleRouter.FeatureNotRegisteredError(feature)
  }
}

export default new FeatureToggleRouter()
