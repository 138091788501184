export const startLoading = async(dispatcher, loaderMessage, callback) => {
  dispatcher('wait/start', loaderMessage, { root: true })
  const response = await callback()
  dispatcher('wait/end', loaderMessage, { root: true })
  return response
}

export const stopLoading = async(dispatcher, loaderMessage) => {
  dispatcher('wait/end', loaderMessage, { root: true })
}
