import Store from '@/store'

export default function() {
  const lang = Store.getters['i18n/langDefault']
  const days = []
  let dayValue = 5

  for (let index = 1; index <= 7; index++) {
    const name = new Date(2007, 1, dayValue).toLocaleString(lang, { weekday: 'long' })
    days.push({ name, value: index - 1 })
    dayValue++
  }

  return days
}
