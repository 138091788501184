export default class BaseClass {
  constructor(list = []) {
    this.list = list
  }

  addItems(list) {
    this.list = []
    list.forEach(item => this.addItem(item))
  }

  mergeItems(list) {
    list.forEach(item => this.addItem(item))
  }

  clearList() {
    this.list = []
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id)
    if (findIndex > -1) {
      this.list.splice(findIndex, 1)
    }
  }

  findIndex(Id) {
    return this.list.findIndex(item => item.id === Id)
  }

  findItem(Id) {
    return this.list.find(item => item.id === Id)
  }

  get length() {
    return this.list.length
  }
}
