// import AutoNumeric from 'autonumeric'

// export const CurrencyDirective = Store => {
//   const format = (el, { value }) => {
//     let priceValue = parseFloat(value)
//     const isBuggyValue = priceValue > -1 && priceValue < 0
//     if (!isNaN(priceValue)) {
//       if (isBuggyValue) {
//         priceValue = Math.abs(priceValue)
//       }
//       const config = Store.getters['Settings/currencyOptions']
//       config.roundingMethod = 'B' // Have plugin bug we must set again here
//       priceValue = AutoNumeric.format(priceValue, config)
//       if (isBuggyValue) {
//         priceValue = `-${priceValue}`
//       }
//     }
//     el.innerText = priceValue
//   }

//   return {
//     bind: format,
//     update: format,
//     componentUpdated: format
//   }
// }

export const ScrollDirective = {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  }
}
export const DefaultValue = {
  inserted: function(el, binding) {
    el.value = binding.value
  },
  update: function(el, binding) {
    if (binding.oldValue === binding.value) return
    if (!el.value) el.value = null
    else {
      el.value = binding.value
    }
  }
}
export const CustomClickOutside = {
  bind(el, binding, vnode) {
    let customArg = null
    if (binding.arg) customArg = vnode.context[binding.arg] ? vnode.context[binding.arg] : binding.arg

    el.checkOutsideClick = (event) => {
      // check if element is the clicked target or contains clicked target sub elements. And call provided method
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(customArg)
      }
    }
    document.body.addEventListener('click', el.checkOutsideClick)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.checkOutsideClick)
  }
}
