import VueI18n from 'vue-i18n'
import Vue from 'vue'
import fetchAllTranslation from '@/i18n/translation'
import store from '@/store'

Vue.use(VueI18n)

const getTranslate = async () => {
  const translations = await fetchAllTranslation()
  return translations
}

export const setTranslations = async () => {
  let isAppLoading = store.getters['Global/appIsLoading']
  if (isAppLoading) {
    await getTranslate()
  }
  let messages = store.getters['i18n/translation']
  return new VueI18n({
    locale: store.getters['i18n/langDefault'],
    messages
  })
}
