import store from '@/store'

export default function(searchItem, keyWord) {
  const langDefault = store.getters['i18n/langDefault'] || navigator.language
  if (typeof searchItem === 'object') {
    return searchItem.some(s => s.toLocaleLowerCase(langDefault).match(keyWord.toLocaleLowerCase(langDefault)))
  }
  return keyWord.slice(0, 1) === '%'
        ? !!searchItem.toLocaleLowerCase(langDefault).match(keyWord.slice(1).toLocaleLowerCase(langDefault))
        : searchItem.toLocaleLowerCase(langDefault).startsWith(keyWord.toLocaleLowerCase(langDefault))
}
