import Vue from 'vue'
import VueWait from 'vue-wait'
import LoadingComponent from '@/view/global/loading'

Vue.use(VueWait)
Vue.component('Loading', LoadingComponent)

export default new VueWait({
  useVuex: true,
  vuexModuleName: 'wait',
  registerComponent: false,
  componentName: 'Loading',
  registerDirective: false
})
