export const isDev = () =>
  (window.VUE_APP_APP_ENV || process.env.VUE_APP_APP_ENV) === "development";
export const isTest = () =>
  (window.VUE_APP_APP_ENV || process.env.VUE_APP_APP_ENV) === "test";
export const isStaging = () =>
  (window.VUE_APP_APP_ENV || process.env.VUE_APP_APP_ENV) === "staging";
export const isProd = () =>
  (window.VUE_APP_APP_ENV || process.env.VUE_APP_APP_ENV) === "production";

export const Print = function(title, data, status = true) {
  if (isDev() || isTest()) {
    let emoji = `✅ `;
    if (!status) emoji = `⁉️ `;
    console.group(emoji + title);
    console.log(data);
    console.groupEnd();
  }
};
