import ":root/static/config.js";

import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "@/store";
import { setTranslations } from "@/i18n";
import filters from "@/utils/filters";
import FeatureToggleRouter from "@/utils/feature-toggle-router";
import FeatureToggleConfig from "./feature-toggle-config";
import PortalVue from "portal-vue";
import "@/utils/scss"; // use scss variable in vue template
import initSSO from "@/sso/initSSO";

// Components
import ActionButton from "@/view/global/action-button";
import Alert from "@/view/global/alert";
import BoxView from "@/view/global/box";
import Button from "@/view/global/button";
import CurrencyInput from "@/view/global/currency-input";
import CustomCheckbox from "@/view/global/custom-checkbox";
import CustomDatePicker from "@/view/global/custom-datepicker";
import CustomSearch from "@/view/global/custom-search";
import CustomMultiselect from "@/view/global/custom-multiselect";
import CustomRadio from "@/view/global/custom-radio";
import DeleteAlert from "@/view/global/delete-alert";
import EmptyState from "@/view/global/empty-state";
import Icon from "@/view/global/icon";
import Modal from "@/view/global/modal";
import NestedTable from "@/view/global/nested-table";
import Notify from "@/view/global/notify";
import Page from "@/view/global/page";
import PageTabButtons from "@/view/global/page-tab-buttons";
import PageHeaderTabs from "@/view/global/page-header-tabs";
import Popover from "@/view/global/popover/index";
import Popup from "@/view/global/popup";
import PopupView from "@/view/global/popup-view";
import Search from "@/view/global/search";
import SelectedButtons from "@/view/global/selected-buttons";

import TableView from "@/view/global/table";
import TableLoading from "@/view/global/table-loading";
import TotalViewList from "@/view/global/total-count-listview";
import Tooltip from "@/view/global/tooltip";
import VehicleLoading from "@/view/global/vehicle-loading";

// Form Components
import customTextInput from "@/view/global/form-custom-component/custom-text-input";
import customNumberInput from "@/view/global/form-custom-component/custom-number-input";
import customSelectInput from "@/view/global/form-custom-component/custom-select-input";
import showValidateError from "@/view/global/form-custom-component/error-container";
import customTextareaInput from "@/view/global/form-custom-component/custom-textarea-input";

// Plugins
import wait from "@/wait";
import vClickOutside from "v-click-outside";
import moment from "moment";
import "moment/locale/tr";
import vShortkey from "vue-shortkey";
import VeeValidate, { Validator } from "vee-validate";
import tr from "vee-validate/dist/locale/tr";

// Directives
// import { CurrencyDirective } from '@/utils/directives'
import {
  ScrollDirective,
  DefaultValue,
  CustomClickOutside,
} from "@/utils/directives";
import { MonthDirective } from "@/utils/date-translations";

// Config for Vue devtools
import { isProd, isStaging } from "@/utils/helper.js";
// Import General Constants
import GeneralConst from "@/utils/general-constants.js";

function initApp() {
  Vue.config.productionTip = false;
  if (isStaging() || isProd()) {
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
  }

  window.moment = moment;

  FeatureToggleRouter.setFeatures(FeatureToggleConfig);

  // Validator settings
  Validator.localize("tr", tr);
  Validator.extend("greater_than", {
    getMessage: (field, [greaterThan]) =>
      `${field} alanı ${greaterThan}'dan büyük bir değer olmalıdır.`,
    validate: (value, [greaterThan]) => value > +greaterThan,
  });

  // Add plugins
  Vue.use(GeneralConst);
  Vue.use(PortalVue);
  Vue.use(vShortkey);
  Vue.use(vClickOutside);
  Vue.use(VeeValidate, {
    events: "input",
    errorBagName: "veeErrors",
    fieldsBagName: "veeFields",
  });

  // Add directives
  // Vue.directive('currency', CurrencyDirective(store))
  Vue.directive("scroll", ScrollDirective);
  Vue.directive("defaultValue", DefaultValue);
  Vue.directive("custom-click-outside", CustomClickOutside);
  Vue.directive("monthformater", MonthDirective());

  // Add components
  Vue.component("ActionButton", ActionButton);
  Vue.component("Alert", Alert);
  Vue.component("BoxView", BoxView);
  Vue.component("Button", Button);
  Vue.component("CurrencyInput", CurrencyInput(store));
  Vue.component("CustomCheckbox", CustomCheckbox);
  Vue.component("CustomDatepicker", CustomDatePicker);
  Vue.component("CustomSearch", CustomSearch);
  Vue.component("CustomMultiselect", CustomMultiselect);
  Vue.component("CustomRadio", CustomRadio);
  Vue.component("DeleteAlert", DeleteAlert);
  Vue.component("EmptyState", EmptyState);
  Vue.component("Icon", Icon);
  Vue.component("Modal", Modal);
  Vue.component("NestedTable", NestedTable);
  Vue.component("Notify", Notify);
  Vue.component("Page", Page);
  Vue.component("PageTabButtons", PageTabButtons);
  Vue.component("PageHeaderTabs", PageHeaderTabs);
  Vue.component("Popup", Popup);
  Vue.component("Popover", Popover);
  Vue.component("PopupView", PopupView);
  Vue.component("Search", Search);
  Vue.component("SelectedButtons", SelectedButtons);
  Vue.component("TableView", TableView);
  Vue.component("TableLoading", TableLoading);
  Vue.component("TotalViewList", TotalViewList);
  Vue.component("Tooltip", Tooltip);
  Vue.component("VehicleLoading", VehicleLoading);
  // Form Components
  Vue.component("customTextInput", customTextInput);
  Vue.component("customNumberInput", customNumberInput);
  Vue.component("customSelectInput", customSelectInput);
  Vue.component("showValidateError", showValidateError);
  Vue.component("customTextareaInput", customTextareaInput);

  // Add filters
  Object.entries(filters).forEach(([key, filter]) => {
    Vue.filter(key, filter);
  });

  store.commit("Global/SET_APP_LOADING", true);
  const app = async () => {
    return new Vue({
      el: "#app",
      router,
      i18n: await setTranslations(),
      store,
      wait, // loading
      render: (h) => h(App),
    });
  };

  app().then(() => {
    store.commit("Global/SET_APP_LOADING", false);
  });
}

initSSO(initApp);
