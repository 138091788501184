import { Validator } from 'vee-validate'

export default {
  data() {
    return {
      weights: ['27', '28', '29'], // girilen barkodun ilk 2 karakteri bu dizideki elemanlardan biri ise ağırlıklı barkod olur
      weightedUnitIds: ['1caa3710-263f-48c5-b07a-f954108e9046', '97c086a1-f755-4826-bfc2-db31430ffec4', '07ca173d-36cf-45af-9bad-448b52e94466']
    }
  },
  created() {
    const self = this
    Validator.extend('verify_weighted_barcode', {
      // Custom validation message
      getMessage: () => self.$t('Global.WeightedBarcode_ValidationMessage'),
      // Custom validation rule
      validate: (value, [unitId]) => new Promise(resolve => {
        resolve({
          valid: this.validateWieghtedBarcode(value, unitId)
        })
      })
    })
  },
  methods: {
    getWeightedBarcode(barcode) {
      // Eğer weighted değilse barkodun kendisini döner
      let stockBarcode = barcode
      if (barcode.length === 13 && this.isWeighted(barcode)) {
        stockBarcode = barcode.slice(0, 7)
      }
      return stockBarcode
    },

    isWeightedUnit(unitId) {
      return this.weightedUnitIds.some(weightedUnitId => weightedUnitId === unitId)
    },

    isWeighted(barcode) {
      return this.weights.some(item => item === barcode.slice(0, 2))
    },

    validateWieghtedBarcode(barcode, unitId) {
      const isWeighted = this.isWeighted(barcode)
      return isWeighted ? this.isWeightedUnit(unitId) &&
      barcode.length === 7 : true
    },

    getQuantityFromBarcode(barcode, unitId) {
      const isWeighted = this.isWeighted(barcode)
      const isValid = isWeighted ? this.isWeightedUnit(unitId) : true
      let quantity = null
      let stockBarcode = barcode
      if (barcode.length === 13 && isWeighted && isValid) {
        stockBarcode = barcode.slice(0, 7)
        quantity = unitId === '07ca173d-36cf-45af-9bad-448b52e94466' ? (barcode.slice(7, 12)) : (barcode.slice(7, 12) / 1000) // unitId control for pice unit
      }
      return { isWeighted, quantity, barcode: stockBarcode }
    }
  }
}
