<template lang="pug">
div(:is="template")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LoginNo from '@/view/global/template/Login-no'
import LoginYes from '@/view/global/template/Login-yes'
import { Validator } from 'vee-validate'
import { Print } from '@/utils/helper'

export default {
  name: 'App',
  components: {
    LoginNo,
    LoginYes
  },
  data () {
    return {
      checkSuspendSalesExistInterval: 0
    }
  },
  computed: {
    ...mapGetters('i18n', ['langDefault', 'langSupport']),
    ...mapGetters('Auth', ['needsNewAgreementConfirmation']),
    template () {
      if (['Register', 'Tenant', 'Login', 'ForgotPassword', 'ResetPassword', 'ConfirmEmail', 'SupportUser'].indexOf(this.$route.name) >= 0) {
        return 'LoginNo'
      } else {
        return 'LoginYes'
      }
    },
    dictionary () {
      let dictionary = {}
      this.langSupport.forEach(lang => {
        dictionary[lang] = {
          messages: {
            is_not: (field) => {
              Print(this.getErrorMessage(field, 'validation_invalid_error_message'))
              Print(this.$t(`Global.validation_invalid_error_message`, { field }))
              return this.getErrorMessage(field, 'validation_invalid_error_message')
            },
            very_singularity: (field) => {
              Print(this.getErrorMessage(field, 'verySingularityMessages'), this.langDefault, field)
              Print(this.$t(`Global.verySingularityMessages`, { field }))
              return this.getErrorMessage(field, 'verySingularityMessages')
            },
            unique_value: field => {
              Print(this.getErrorMessage(field, 'verySingularityMessages'), this.langDefault, field)
              Print(this.$t(`Global.verySingularityMessages`, { field }))
              return this.getErrorMessage(field, 'verySingularityMessages')
            },
            multiplyTwoField: field => {
              Print(this.getErrorMessage(field, 'MultiplyValueMessages'), this.langDefault, field)
              Print(this.$t(`Global.MultiplyValueMessages`, { field }))
              return this.getErrorMessage(field, 'MultiplyValueMessages')
            }
          }
        }
      })
      return dictionary
    }
  },

  async beforeMount () {
    await this.setLanguage()
    if (this.template === 'LoginYes') {
      await this.SET_ACTIVE_CURRENCY()
      await this.checkSuspendSalesExist()
      this.checkSuspendSalesExistInterval = setInterval(() => this.checkSuspendSalesExist(), 1000 * 60)
    } else {
      clearInterval(this.checkSuspendSalesExistInterval)
    }
    const self = this
    const verySingularity = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value, [url, param]) {
        return new Promise(resolve => {
          let params = {
            [param]: value
          }
          self.verySingularityValidate({ url, params })
            .then(res => {
              resolve({
                valid: res.data.isAvailable
              })
            })
            .catch(e => {
              resolve({
                valid: false
              })
            })
        })
      }
    }

    const unqiueValue = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value, [list, uniqueValue]) {
        return new Promise(resolve => {
          let hasItem = list.find(item => item[uniqueValue] === value)
          resolve({
            valid: !hasItem
          })
        })
      }
    }

    const multiplyTwoField = {
      getMessage: () => 'nomessage',
      validate: (val, [otherField]) => new Promise(resolve => {
        resolve({ valid: ((parseInt(val) * parseInt(otherField)).toString().length <= this.$getConst('MAX_DIGITS_FOR_QUANTITY')) })
      })
    }

    Validator.extend('very_singularity', verySingularity)
    Validator.extend('unique_value', unqiueValue)
    Validator.extend('multiplyTwoField', multiplyTwoField)
    Validator.localize(this.dictionary)
  },

  watch: {
    async 'template' () {
      await this.setLanguage()
      await this.SET_ACTIVE_CURRENCY()
      if (this.template === 'LoginYes') {
        await this.checkSuspendSalesExist()
        this.checkSuspendSalesExistInterval = setInterval(() => this.checkSuspendSalesExist(), 1000 * 60)
      } else {
        clearInterval(this.checkSuspendSalesExistInterval)
      }
    },
    '$route' () {
      if (this.template === 'LoginYes' && this.needsNewAgreementConfirmation) {
        this.$router.push({
          name: 'AgreeConfirm'
        })
      }
    },
    async langDefault () {
      if (this.template === 'LoginYes') {
        await this.getUnitList()
      }
    }
  },

  mounted () {
    if (this.template === 'LoginYes' && this.needsNewAgreementConfirmation) {
      this.$router.push({
        name: 'AgreeConfirm'
      })
    }
    this.darkMode()
    this.$nextTick(() => {
      this.mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')
      this.mediaQueryList.addEventListener('change', this.darkMode)
    })
  },
  methods: {
    ...mapActions('i18n', ['langChange']),
    ...mapActions('Auth', ['Logout']),
    ...mapActions('Global', ['checkSuspendSalesExist', 'verySingularityValidate']),
    ...mapMutations('Global', ['SET_ACTIVE_CURRENCY']),
    ...mapActions('Units', ['getUnitList']),

    getErrorMessage (field, message) {
      return this.$t(`Global.${message}`, { field })
    },
    setLanguage () {
      let browserLang = (navigator.language || navigator.userLanguage) === 'tr' || (navigator.language || navigator.userLanguage) === 'tr-TR' ? 'tr' : 'en'
      let language = this.template === 'LoginNo' ? browserLang : this.langDefault
      this.langChange({
        lang: language,
        app: this
      })
    },
    darkMode () {
      const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      if (isDark) {
        // change favicon with favicon-light.svg and favicon-dark.svg
        document.querySelector('link[rel="icon"]').href = require('/static/favicon-light.svg')
      } else {
        document.querySelector('link[rel="icon"]').href = require('/static/favicon-dark.svg')
      }
    },
  }
}
</script>

<style lang="scss">
  @import 'stylesheet/app';
  .App {
    min-height: 100vh;
  }
</style>
