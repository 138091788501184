const alertbox = () => {
  return {
    methods: {
      alert(obj) {
        if (this.$root.$children[0].$children[0] && this.$root.$children[0].$children[0].$refs.Alertbox) {
          this.$root.$children[0].$children[0].$refs.Alertbox.open(obj)
        }
      }
    }
  }
}

export default alertbox
