function getObjectValue(fieldName, item, defaultValue = 0) {
  if (!item) return defaultValue
  return fieldName.split('.').reduce((startingObject, nextObject) => {
    return (startingObject[nextObject] || startingObject[nextObject] === 0) ? startingObject[nextObject] : ''
  }, item)
}

function isEmpty(item) {
  return item === null ||
    item === undefined ||
    typeof item === 'boolean' ||
    typeof item === 'number' ||
    Object.keys(item).length === 0 ||
    item.length === 0
}

function omit(oldObj, removedKeys) {
  // Array removed keys not working. Fix IT
  let newObj = {}
  Object.keys(oldObj).forEach((item) => {
    removedKeys.forEach((keys, index) => {
      if (keys !== item) newObj[item] = oldObj[item]
    })
  })
  return newObj
}

function debouncer(func, wait, immediate) {
  let timeout

  return function executedFunction() {
    let context = this
    let args = arguments

    let later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    let callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}
// Get only keys and values specified in objectKeys array
function getObjectValuesByKey(objectData = {}, objectKeys = []) {
  if (objectKeys.length === 0 || !objectData) return
  let newObjectData = {}
  Object.keys(objectData).forEach(item => {
    if (objectKeys.includes(item)) newObjectData[item] = objectData[item]
  })
  return newObjectData
}

function vueWaitLoader(func, loaderName) {
  return async function executedFunction() {
    if (this.$wait.is(loaderName)) return

    let context = this
    let args = arguments

    this.$wait.start(loaderName)
    await func.apply(context, args)
    this.$wait.end(loaderName)
  }
}

export {
  getObjectValue,
  isEmpty,
  omit,
  debouncer,
  getObjectValuesByKey,
  vueWaitLoader
}
