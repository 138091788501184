import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isExportPopupVisible: false,
      templateId: null,
      templateType: 0
    }
  },
  computed: {
    ...mapGetters('ExportSettings', ['exportSettingList'])
  },
  methods: {
    exportFrame(id, type, isFinalizeAction, routeName) {
      const routerName = routeName || this.$route.name
      const page = this.exportSettingList.find(i => i.routeName === routerName)
      if ((page && !page.isChecked) && isFinalizeAction) return
      if (id === this.templateId) this.$refs.exportPopupTemplate.printTable()
      this.templateId = id
      this.templateType = type
      this.isExportPopupVisible = true
    }
  }
}
