import HTTP from "@/utils/http";
import vuexStore from "@/store";
import router from "@/router";
import AuthorizationContext from "@/sso/keycloak-instance.js";

const setAuthHeader = (token) => {
  if (!HTTP.defaults.headers.common.Authorization) {
    HTTP.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
};

function toLogin(next) {
  const isSupportUser = vuexStore.getters["Global/isSupportUser"];
  if (isSupportUser) next({ name: "Login" });
  else AuthorizationContext.keycloakInstance.login();
}

export default (to, from, next) => {
  const STORE = JSON.parse(
    localStorage[window.VUE_APP_VUEX_NAME || process.env.VUE_APP_VUEX_NAME] ||
      "{}"
  );
  let pageAuth = to.matched.some((record) => record.meta.auth);
  const tokenValue = STORE.Auth.Token.value;
  const tokenType = STORE.Auth.Token.type;
  const isSupportUser = vuexStore.getters["Global/isSupportUser"];

  if (to.name === "SupportUser" && !isSupportUser) {
    vuexStore.commit("Global/RESET_SUSPEND");
    vuexStore.dispatch("Auth/Logout", null, { root: true }).then(() => {
      router.push({
        path: to.fullPath,
        params: to.query,
      });
      next();
    });
  }
  if (tokenValue) {
    setAuthHeader(tokenValue);
    pageAuth
      ? tokenType === "tenant" && vuexStore.state.Token
        ? next({ name: "Dashboard" })
        : next()
      : tokenType === "tenant"
      ? next()
      : toLogin(next);
  } else {
    pageAuth ? next() : toLogin(next);
  }
};
