
function randomRange(min, max) {
  const randomArray = new Uint8Array(1)
  window.crypto.getRandomValues(randomArray)
  const range = max - min + 1
  const maxRange = max + 55
  if (randomArray[0] >= Math.floor(maxRange / range) * range) return randomRange(min, max)
  return min + (randomArray[0] % range)
}
export default randomRange
