export default {
  namespaced: true,
  state: {
    langDefault: localStorage.lang || navigator.language,
    langFallback: 'tr',
    langSupport: [],
    translation: {},
    langChangedTime: null
  },

  getters: {
    langDefault: state => state.langDefault,
    langSupport: state => state.langSupport,
    translation: state => state.translation,
    langChangedTime: state => state.langChangedTime
  },

  actions: {
    langChange: ({ commit }, payload) => {
      commit('LANG_CHANGE', payload)
    }
  },
  mutations: {

    SET_LANG_SUPPORT(state, langs) {
      state.langSupport = langs
    },

    SET_TRANSLATIONS(state, translation) {
      state.translation = translation
    },

    SET_LAST_CHANGED_DATE(state, timeStamp) {
      state.langChangedTime = timeStamp
    },

    LANG_CHANGE(state, { lang, app }) {
      state.langDefault = lang
      app.$root.$i18n.locale = lang
      app.$root.$validator.localize(lang)
      window.moment.locale(lang)
      let html = document.querySelector('html')
      html.setAttribute('lang', lang)
    }

  }
}
