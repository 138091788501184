const validateRules = {
  MAX_DIGITS_FOR_QUANTITY: 18,
  DECIMAL_FOR_QUANTITY: 6,
  INPUT_DELAY_TIME: 0
}

validateRules.install = function(Vue) {
  Vue.prototype.$getConst = (key) => {
    return validateRules[key]
  }
}

export default validateRules
