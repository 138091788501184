import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// Global
import Global from "./global";
import i18n from "@/i18n/store";
import Popup from "@/view/global/popup/store";
import Notify from "@/view/global/notify/store";
import AlertDelete from "@/view/global/delete-alert/store";

// Pages
import Auth from "@/view/pages/Auth/store";
import Settings from "@/view/pages/Settings/store";
import Dashboard from "@/view/pages/Dashboard/store";
import IncomeStats from "@/view/pages/Reports/store/income";
import Tax from "@/view/pages/Tax/store";
import Group from "@/view/pages/Group/store";
import Supplier from "@/view/pages/Supplier/store/supplier";
import SupplierGroups from "@/view/pages/Supplier/store/supplier-groups";
import Stock from "@/view/pages/Stock/store";
import RecipeGroups from "@/view/pages/Recipes/store/recipe-groups";
import Recipes from "@/view/pages/Recipes/store/recipes";
import Stores from "@/view/pages/Stores/store/stores";
import StoreGroups from "@/view/pages/Stores/store/store-groups";
import Despatch from "@/view/pages/Despatch/store";
import EInvoice from "@/view/pages/Despatch/store/eInvoice";
import Refund from "@/view/pages/Despatch/store/refund";
import Inventories from "@/view/pages/Inventory/store";
import MenuItems from "@/view/pages/MenuItems/store";
import OnHand from "@/view/pages/OnHand/store";
import Units from "@/view/pages/Units/store";
import Reports from "@/view/pages/Reports/store";
import PriceQuote from "@/view/pages/PriceQuote/store";
import UserGroups from "@/view/pages/Settings/store/";
import Transfer from "@/view/pages/Transfer/store/";
import Waste from "@/view/pages/Wastes/store";
import SuspendedSales from "@/view/pages/MenuItems/store/suspendedSales";
import Payments from "@/view/pages/Payment/store/payment-follow";
import PaymentTypes from "@/view/pages/Payment/store/payment-types";
import PaymentCalendar from "@/view/pages/Payment/store/payment-calendar";
import DirectOrders from "@/view/pages/Requests/store/directOrders";
import Request from "@/view/pages/Requests/store";
import Orders from "@/view/pages/Requests/store/orders";
import RequestedTransfers from "@/view/pages/Requests/store/transfer";
import Lists from "@/view/pages/Lists/store";
import ListsInventory from "@/view/pages/Lists/Inventory/store/index";
import ListsDespatch from "@/view/pages/Lists/Despatch/store/index";
import ListsRequestTransfer from "@/view/pages/Lists/RequestTransfer/store/index";
import ListsRequestOrder from "@/view/pages/Lists/RequestOrder/store/index";
import ListsWaste from "@/view/pages/Lists/Waste/store/index";
import ListsProduction from "@/view/pages/Lists/Production/store/index";
import ListsTransferSalesPrice from "@/view/pages/Lists/TransferSalesPrice/store/index";
import ListsYield from "@/view/pages/Lists/Yield/store/index";
import ScheduleJob from "@/view/pages/ScheduledJob/store/index";
import Production from "@/view/pages/Production/store/index";
import Hierarchy from "@/view/pages/Hierarchy/store/index";
import ProductionPlaning from "@/view/pages/Production/store/planing";
import RawMaterialPlanning from "@/view/pages/Production/store/raw-material-planning";
import OrderIntegrationStatus from "@/view/pages/OrderIntegrationStatus/store/index";
import OptionalFields from "@/view/pages/OptionalFields/store/index";
import Allergen from "@/view/pages/OptionalFields/store/allergen";
import NutritionalValues from "@/view/pages/OptionalFields/store/nutritions";
import Integration from "@/view/pages/Integration/store";
import IntegrationAccountingLogo from "@/view/pages/Integration/Accounting/Logo/store/index";
import IntegrationSimpraPos from "@/view/pages/Integration/Pos/Simpra/store/index";
import IntegrationB2b from "@/view/pages/Integration/B2b/B2b/store/index";
import IntegrationB2bLogo from "@/view/pages/Integration/B2b/Logo/store/index";
import IntegrationWarehouseManagementAxata from "@/view/pages/Integration/WarehouseManagement/Axata/store/index";
import IntegrationOtherEMKO from "@/view/pages/Integration/EMKO/store/index";
import EConversionStoreGroupConfig from "@/view/pages/Integration/eConversion/store/index";
import Reportings from "@/view/pages/Reportings/store/index";
import MenuItemsSettings from "@/view/pages/MenuItemsSettings/store/index";
import DateFilterSettings from "@/view/pages/Settings/store/date-filter-settings";
import ExportSettings from "@/view/pages/Settings/store/export-settings";
import DocumentSettings from "@/view/pages/Settings/store/document-settings";
import ApproveOperations from "@/view/pages/ApproveOperations/store/index";
import Yield from "@/view/pages/Yield/store/index";
import HelpCenter from "@/view/pages/HelpCenter/store/index";
import ListsShelfStorageLocation from "@/view/pages/Lists/ShelfStorageLocation/store/index";
import IntegrationLogs from "@/view/pages/IntegrationLogs/store/index";
import OtherExpenses from "@/view/pages/OtherExpenses/store/index";
import ExpenseList from "@/view/pages/Lists/Expense/store/index"
import Exchange from "@/view/pages/Exchange/store/index"
import SalesForecasting from "@/view/pages/Integration/SalesForecasting/store/index"
import LanguageTranslations from "@/view/pages/LanguageTranslations/store/index"

Vue.use(Vuex);

const debug =
  (window.VUE_APP_NODE_ENV || process.env.VUE_APP_NODE_ENV) !== "production";

const store = new Vuex.Store({
  modules: {
    // Globals
    Global,
    i18n,
    Popup,
    Notify,
    AlertDelete,
    // Pages
    Auth,
    Settings,
    Dashboard,
    Tax,
    Stock,
    Group,
    RecipeGroups,
    Recipes,
    UserGroups,
    Stores,
    StoreGroups,
    Supplier,
    SupplierGroups,
    Despatch,
    EInvoice,
    Refund,
    IncomeStats,
    Inventories,
    MenuItems,
    OnHand,
    Units,
    Reports,
    PriceQuote,
    Transfer,
    Waste,
    SuspendedSales,
    Payments,
    PaymentTypes,
    PaymentCalendar,
    DirectOrders,
    Request,
    Orders,
    RequestedTransfers,
    Lists,
    ListsInventory,
    ListsRequestTransfer,
    ListsRequestOrder,
    ListsDespatch,
    ListsWaste,
    ListsProduction,
    ListsTransferSalesPrice,
    ListsYield,
    ScheduleJob,
    Production,
    ProductionPlaning,
    RawMaterialPlanning,
    OrderIntegrationStatus,
    OptionalFields,
    Integration,
    IntegrationAccountingLogo,
    IntegrationSimpraPos,
    IntegrationB2b,
    IntegrationB2bLogo,
    IntegrationWarehouseManagementAxata,
    IntegrationOtherEMKO,
    EConversionStoreGroupConfig,
    Hierarchy,
    Reportings,
    MenuItemsSettings,
    DateFilterSettings,
    ExportSettings,
    ApproveOperations,
    Yield,
    Allergen,
    NutritionalValues,
    HelpCenter,
    DocumentSettings,
    ListsShelfStorageLocation,
    IntegrationLogs,
    OtherExpenses,
    ExpenseList,
    Exchange,
    SalesForecasting,
    LanguageTranslations
  },
  strict: debug,
  plugins: [
    createPersistedState({
      key: window.VUE_APP_VUEX_NAME || process.env.VUE_APP_VUEX_NAME,
      paths: [
        "Auth",
        "Global",
        "Settings.permissions",
        "Settings.allPermissionList",
        "Settings.roleList",
        "Settings.UserInfo",
        "i18n"
      ]
    })
  ]
});

export default store;
