export default {
  data() {
    return {
      isDisabledSubmit: false
    }
  },
  mounted() {
    this.enableSubmitButton(false)
  },
  methods: {
    enableSubmitButton() {
      this.isDisabledSubmit = false
    },
    disableSubmitButton() {
      this.isDisabledSubmit = true
    }
  }
}
