import { mapActions } from 'vuex'

export default {
  computed: {
    isLoadingDownloadActions() {
      return this.$wait.is(['downloadReports', 'exportExcel'])
    }
  },
  methods: {
    ...mapActions('Reports', [
      'downloadReports'
    ]),

    ...mapActions('Global', [
      'exportExcel'
    ]),

    async downloadCSVAction({
      refName,
      url,
      params
    }) {
      const link = refName === null ? this.$refs.downloadButton : this.$refs[refName].$refs.downloadButton
      this.downloadData = await this.downloadReports({ url, params })
      link.href = this.downloadData
      link.click()
    },

    async downloadExcelAction({
      url,
      refName,
      ref = null,
      params,
      method = 'POST'
    }) {
      await this.exportExcel({url, params, method})
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]))
          const link = ref || (refName === null ? this.$refs.downloadButton : this.$refs[refName].$refs.downloadButton)
          link.href = downloadUrl
          link.click()
        })
    }
  }
}
