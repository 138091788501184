import moment from 'moment'

export default [
  {
    key: 'Daily',
    value: '-1',
    fn: (val, form) => {
      return [ moment(), moment().add(val, 'd') ].sort((a, b) => a - b).map(item => item.format(form))
    }
  },
  {
    key: 'Yesterday',
    value: '100',
    fn: (val, form) => {
      return [ moment().subtract(1, 'days').format(form), moment().subtract(1, 'days').format(form) ]
    }
  },
  {
    key: 'Today',
    value: '200',
    fn: (val, form) => {
      return [ moment().format(form), moment().format(form) ]
    }
  },
  {
    key: 'ThisWeek',
    value: '210',
    fn: (val, form) => {
      return [ moment().startOf('week').format(form), moment().endOf('week').format(form) ]
    }
  },
  {
    key: 'LastWeek',
    value: '110',
    fn: (val, form) => {
      return [ moment().subtract(1, 'w').startOf('week').format(form), moment().subtract(1, 'w').endOf('week').format(form) ]
    }
  },
  {
    key: 'ThisMonth',
    value: '220',
    fn: (val, form) => {
      return [ moment().startOf('month').format(form), moment().endOf('month').format(form) ]
    }
  },
  {
    key: 'ThisMonthUntilToday',
    value: '221',
    fn: (val, form) => {
      return [ moment().startOf('month').format(form), moment().format(form) ]
    }
  },
  {
    key: 'ThisMonthUntilTomorrow',
    value: '222',
    fn: (val, form) => {
      return [ moment().startOf('month').format(form), moment().add(1, 'd').format(form) ]
    }
  }
  /* {
    key: 'LastMonth',
    value: '120',
    fn: (val, form) => {
      return [ moment().subtract(1, 'months').startOf('months').format(form), moment().subtract(1, 'months').endOf('months').format(form) ]
    }
  }, */
]
