const products = [
  {
    name: "pos",
    text: "POS",
    url: "https://pos.simprasuite.com/",
    desc: "Satışlarını yönet",
  },
  {
    name: "inventory",
    text: "Inventory",
    url: "https://inventory.simprasuite.com/login",
    desc: "Envanterini takip et",
  },
  {
    name: "link",
    text: "Link",
    url: "https://backoffice.simpralink.com/",
    desc: "Ödemeleri yönet",
  },
  {
    name: "c&p",
    text: "Check & Place",
    url: "https://www.checkandplace.com/",
    desc: "Rezervasyon yönet",
  },
  {
    name: "loyalty",
    text: "Loyalty",
    url: "https://simprasuite.com",
    desc: "Sadakat yönetimi",
  },
  {
    name: "insight",
    text: "Insight",
    url: "https://simprasuite.com",
    desc: "Tüm raporlar cebinde",
  },
  {
    name: "gmu",
    text: "GMU",
    url: "https://gmupos.protel.com.tr",
    desc: "Mali uygulama",
  },
  {
    name: "quick",
    text: "Quick",
    url: "https://simprasuite.com",
    desc: "Ödeme takip servisi",
  },
  {
    name: "pms",
    text: "PMS",
    url: "https://pms.simprasuite.com/",
    desc: "Otelini yönet",
  },
];

export default products;
