import Keycloak from "keycloak-js";
import products from "@/constant/products.js";
import axios from "axios";

const AuthorizationContext = (() => {
  let _keycloak = null;

  const context = {
    _instance: new Keycloak({
      url: window.VUE_APP_KC_SERVER_URL || process.env.VUE_APP_KC_SERVER_URL,
      realm: "SIMPRA",
      clientId: "inventory-api-client",
    }),
    _isAuthenticated: false,
    _isInitializing: false,
    _isInitialized: false,
    _token: {},
    _refreshToken: null,

    get keycloakInstance() {
      return this._instance;
    },
    get isAuthenticated() {
      return this._isAuthenticated;
    },
    set isAuthenticated(val) {
      this._isAuthenticated = val;
    },

    get isInitializing() {
      return this._isInitializing;
    },
    set isInitializing(val) {
      this._isInitializing = val;
    },

    get isInitialized() {
      return this._isInitialized;
    },
    set isInitialized(val) {
      this._isInitialized = val;
    },

    get token() {
      return this._token;
    },
    set token(val) {
      this._token = val;
    },

    get refreshToken() {
      return this._refreshToken;
    },
    set refreshToken(val) {
      this._refreshToken = val;
    },

    isAvailable() {
      return (
        !this.isAuthenticated && !this.isInitializing && !this.isInitialized
      );
    },
    init() {
      if (!this.isInitializing && !this.isInitialized) {
        this.isInitializing = true;

        return new Promise((resolve, reject) => {
          return this.keycloakInstance
            .init({
              onLoad: "login-required",
            })
            .then(async (response) => {
              await this.assignContextArguments(response);
              resolve(response);
            })
            .catch((err) => {
              this.isAuthenticated = false;
              this.isInitializing = false;

              reject(err);
            });
        });
      }
    },
    assignContextArguments(loginResult) {
      return new Promise(async (resolve, _reject) => {
        const token = this.keycloakInstance?.tokenParsed || {};
        this.isAuthenticated = loginResult;
        this.isInitializing = false;
        this.isInitialized = true;
        this.token = token;
        this.refreshToken = this.keycloakInstance.refreshToken;
        this.user.profile = await this.keycloakInstance.loadUserProfile();
        this.user.memberOf = token.memberOf || [];
        this.user.allProducts =
          token.memberOf?.map((item) => item.replace("/", "").toLowerCase()) ||
          [];
        this.user.authorizedProducts = products.filter((item) =>
          this.user.allProducts.includes(item.name)
        );
        this.user.unAuthorizedProducts = products.filter(
          (item) => !this.user.allProducts.includes(item.name)
        );

        resolve();
      });
    },
    logout() {
      this.keycloakInstance.logout();
    },
    getAdminToken() {
      return "realms/master/protocol/openid-connect/token";
    },
    getNewToken() {
      try {
        return this.keycloakInstance.updateToken(-1);
      } catch (err) {
        console.log("Keycloak refresh token error -> ", err);
      }
    },
    user: {
      _profile: {},
      _memberOf: [],
      _allProducts: [],
      _authorizedProducts: [],
      _unAuthorizedProducts: [],

      get profile() {
        return this._profile;
      },
      set profile(val) {
        this._profile = val;
      },

      get allProducts() {
        return this._allProducts;
      },
      set allProducts(val) {
        this._allProducts = val;
      },

      get memberOf() {
        return this._memberOf;
      },
      set memberOf(val) {
        this._memberOf = val;
      },

      get authorizedProducts() {
        return this._authorizedProducts;
      },
      set authorizedProducts(val) {
        this._authorizedProducts = val;
      },

      get unAuthorizedProducts() {
        return this._unAuthorizedProducts;
      },
      set unAuthorizedProducts(val) {
        this._unAuthorizedProducts = val;
      },

      updateProfile(payload) {
        return new Promise((resolve, reject) => {
          axios({
            method: "POST",
            url: `${
              window.VUE_APP_KC_SERVER_URL || process.env.VUE_APP_KC_SERVER_URL
            }/realms/SIMPRA/account/`,
            data: payload,
            headers: {
              Authorization: "Bearer " + context.keycloakInstance.token,
              "Content-Type": "application/json",
            },
          })
            .then((_response) => {
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      updatePassword(payload) {
        return new Promise((resolve, reject) => {
          axios({
            method: "PUT",
            url: `${
              window.VUE_APP_KC_SERVER_URL || process.env.VUE_APP_KC_SERVER_URL
            }admin/realms/master/users/${
              context.keycloakInstance.tokenParsed.sub
            }/reset-password`,
            data: payload,
            headers: {
              Authorization: "Bearer " + context.keycloakInstance.token,
              "Content-Type": "application/json",
            },
          })
            .then((_response) => {
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    },
  };

  if (!_keycloak) {
    _keycloak = { ...context };
  }

  return _keycloak;
})();

export default AuthorizationContext;
