import requestHandler from "@/utils/requestHandler";
import HTTP from "@/utils/http";

export default {
  namespaced: true,

  state: {
    Languages: [],
    showLanguageDropdown: false,
    CurrencyList: [],
    timeZoneList: [],
    currencySymbolOrIsoCode: "isoCode",
    activeCurrency: {},
    isSuspendTransactionInProgress: false,
    suspendSalesExist: false,
    MenuMinimalize: false,
    appIsLoading: true,
    appVersion: "",
    showActions: false, // For action-button usage
    prevActionValue: {}, // For action-button usage
    isSupportUser: false,
  },

  getters: {
    Languages: (state) => state.Languages,
    CurrencyList: (state) => state.CurrencyList,
    TimeZoneList: (state) => state.timeZoneList,
    showLanguageDropdown: (state) => state.showLanguageDropdown,
    suspendSalesExist: (state) => state.suspendSalesExist,
    isSuspendTransactionInProgress: (state) =>
      state.isSuspendTransactionInProgress,
    appVersion: (state) => state.appVersion,
    MenuMinimalize: (state) => state.MenuMinimalize,
    activeCurrency: (state) => state.activeCurrency,
    findCurrency: (state) => (id) =>
      state.CurrencyList.find((cur) => cur.id === id),
    appIsLoading: (state) => state.appIsLoading,
    findLanguage: (state) => (locale) => {
      return state.Languages.find((lang) => {
        return lang.isoCode.split("-")[0] === locale;
      });
    },
    appLanguageInfo: (state, rootGetters, rootState) =>
      rootGetters.findLanguage(rootState["i18n"].langDefault),
    showActions: (state) => state.showActions, // For action-button usage
    prevActionValue: (state) => state.prevActionValue, // For action-button usage
    isSupportUser: (state) => state.isSupportUser,
  },

  actions: {
    async getCurrencyList({ commit, dispatch, rootGetters }, languageId) {
      const loadingName = "Global/getCurrencyList";
      if (!languageId) languageId = rootGetters["Auth/languageId"];

      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(`Currency/definition/all?languageId=${languageId}`);
        },
        success: async (result) => {
          commit("SET_CURRENCY_LIST", result.data.currencyDefinitionList);
          return result;
        },
      });

      return results;
    },

    async getUTCList({ commit }) {
      const loadingName = "getUTCList";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("TimeZone/all");
        },
        success: async (result) => {
          commit("SET_UTC_LIST", result.data);
          return result;
        },
      });
      return results;
    },

    setLanguageDropdownVisibility({ commit }, visibility) {
      commit("SET_LANGUAGE_DROPDOWN_VISIBILITY", visibility);
    },

    async checkSuspendSalesExist({ commit, dispatch }) {
      const loadingName = "Global/suspendSalesExist";

      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("Info");
        },
        success: async (result) => {
          commit(
            "SET_SUSPEND_SALES_IN_PROGRESS",
            result.data.isSuspendTransactionInProgress
          );
          commit("SET_SUSPEND_SALES_INFO", result.data.suspendTransactionExist);
          commit("SET_APP_VERSION", result.data.version);
          return result;
        },
      });

      return results;
    },

    async verySingularityValidate({ commit }, payload) {
      const loadingName = "Global/verySingularityValidate";

      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(payload.url, {
            params: { ...payload.params },
          });
        },
        success: async (result) => result,
      });
      return results;
    },

    async exportExcel({ commit }, { url, params, method }) {
      const loadingName = "exportExcel";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP({
            url: url,
            method: method,
            data: {
              ...params,
              exportType: 1,
            },
            responseType: "blob",
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async importFile({ commit }, { url, file }) {
      let formData = new FormData();
      formData.append("file", file);

      const loadingName = "importFile";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async getTemplateExport({ commit }, payload) {
      const loadingName = "getTemplate";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP({
            url: "Template/export",
            method: "POST",
            data: {
              ...payload,
            },
            responseType: "blob",
          });
        },
        success: (result) => result,
      });
      return results;
    },
  },

  mutations: {
    SET_CURRENCY_LIST(state, list) {
      state.CurrencyList = list;
    },
    RESET_SUSPEND(state) {
      state.suspendSalesExist = false;
    },
    SET_LANG(state, languages) {
      state.Languages = languages;
    },
    SET_ACTIVE_CURRENCY(state) {
      const rootGetters = this.getters;
      state.activeCurrency = state.CurrencyList.find((currency) => {
        return currency.id === rootGetters["Auth/baseCurrencyId"];
      });
    },
    SET_LANGUAGE_DROPDOWN_VISIBILITY(state, visibility) {
      state.showLanguageDropdown = visibility;
    },

    SET_SUSPEND_SALES_INFO(state, suspendSalesExistInfo) {
      state.suspendSalesExist = suspendSalesExistInfo;
    },

    SET_SUSPEND_SALES_IN_PROGRESS(state, isSuspendTransactionInProgress) {
      state.isSuspendTransactionInProgress = isSuspendTransactionInProgress;
    },

    SET_APP_VERSION(state, version) {
      state.appVersion = version;
    },

    TOGGLE_MENU_MINIMALIZE(state, minimalizeMenu) {
      if (minimalizeMenu) state.MenuMinimalize = true;
      else state.MenuMinimalize = !state.MenuMinimalize;
    },

    RESET_MENU_MINIMALIZE(state) {
      state.MenuMinimalize = false;
    },

    SET_APP_LOADING(state, isLoading) {
      state.appIsLoading = isLoading;
    },

    SET_UTC_LIST(state, data) {
      state.timeZoneList = data.timeZoneList;
    },

    SET_SHOW_ACTION_STATUS(state, payload) {
      state.prevActionValue = payload.prevActionValue;
      state.showActions = payload.value;
    },

    SET_SUPPORT_USER_STATUS(state, status) {
      state.isSupportUser = status;
    },
  },
};
