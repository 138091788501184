import store from '@/store'
import HTTP from '@/utils/http'

let fetchAllTranslation = async function() {
  const res = await HTTP.get('Translation/allkeys', {
    params: {
      TimeStamp: store.getters['i18n/langChangedTime']
    }
  }) // fetching all translations
  const resLang = await HTTP('Language/all') // fetching all languages tr, en, hu ... vs.
  const langChangedTime = res.data.timeStamp // timeStamp is last changed date of translations.

  let LANG = res.data.translationList
  if (!LANG) return
  const translations = LANG.reduce((isoCode, item) => {
    isoCode[item.languageIsoCode.split('-')[0]] = item.translationsByGroup.reduce((i18n, item) => {
      i18n[item.groupName] = item.translations
      return i18n
    }, {})
    return isoCode
  }, {})
  store.commit('Global/SET_LANG', resLang.data.languageList)
  await store.commit('i18n/SET_LAST_CHANGED_DATE', langChangedTime)
  await store.commit('i18n/SET_TRANSLATIONS', translations)
  await store.commit('i18n/SET_LANG_SUPPORT', Object.keys(translations))
  return translations
}

export default fetchAllTranslation
